//  background-color: ${(p) => (p.test2Color ? p.test2Color : 'red')};
//  ${(props) => css` background-color: ${props.test3Color}; `}
//  ${g.media( 'phone,tablet,desktop', css` width: 5rem; height: 5rem; `, )}
//  background-color: ${g.getColor('accent')};
//import { isDark } from "V3";

import { styled, css } from "V3/Components/styledMixins/styledMixins.js";

export const S = {
    Container: styled.div`
        ${({ isDarkMode, disableSubmit, disableCancel }) => css`
            background-color: ${isDarkMode ? "#333333" : "#999999"};
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            position: relative;
            bottom: 0;
            left: 0;
            z-index: 1000;
            margin-top: 20px;

            & > div {
                width: 60px;
                height: 60px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            & > #left {
                background: ${disableCancel ? "#999999" : "red"};
                pointer-events: ${disableCancel ? "none" : "auto"};
            }

            & > #right {
                background: ${disableSubmit ? "#999999" : "green"};
                pointer-events: ${disableSubmit ? "none" : "auto"};
            }
        `}
    `,
};
